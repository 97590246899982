"use client";

import React from "react";

import { TenantLogoLink } from "components/organisms/navigation";
import AppHeader from "./AppHeader";

function AppHeaderSimple({
	left = <TenantLogoLink />,
	right = null,
}: {
	left?: React.ReactNode;
	right?: React.ReactNode;
}) {
	return (
		<AppHeader>
			<AppHeader.Layout left={left} right={right} variant="simple" />
		</AppHeader>
	);
}

export default AppHeaderSimple;
